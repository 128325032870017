.home {
  padding-top: 80px; /* Adjust this value to match your navbar height */
}

.hero-overlay {
  position: relative;
  background-image: url('/public/Assets/Wallpaper.jpg');
  background-size: cover;
  background-position: center;
  height: calc(100vh - 80px); /* Adjust this value to match your navbar height */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ... rest of the CSS remains the same ... */

.hero-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content {
  text-align: center;
  color: #fff;
  z-index: 1;
  padding: 2rem;
  max-width: 800px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: #fff;
  background-color: #d81b60;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #ad1457;
  transform: scale(1.05);
}

.content-section {
  display: flex;
  justify-content: space-around;
  padding: 4rem 2rem;
  background-color: #f5f5f5;
}

.services-overview, .about-us {
  flex-basis: 45%;
  max-width: 500px;
}

@media (max-width: 768px) {
  .content-section {
    flex-direction: column;
    align-items: center;
  }

  .services-overview, .about-us {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
}