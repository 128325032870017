.portfolio-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.nav-link {
  padding: 10px 15px;
  margin: 0 5px;
  text-decoration: none;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover, .nav-link.active {
  background-color: #007bff; /* Brighter blue for better visibility */
  color: #fff;
  transform: translateY(-2px); /* Subtle lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.portfolio {
  padding: 20px;
  text-align: center;
  max-width: calc(100% - 100px); /* Adjusting max-width to allow for border */
  margin: 20px auto; /* Adding margin to create a border effect */
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.gallery img {
  width: calc(100% / 4 - 20px); /* Adjust width to fill row with 3 images per row minus gap */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for interaction */
}

.gallery img:hover {
  transform: scale(1.05); /* Slight scale on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
}

.gallery img:hover {
  transform: scale(1.05); /* Slight scale on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.caption {
  text-align: center;
  color: #ccc;
  padding: 10px 20px;
}

/* Responsive adjustments for modal */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .gallery img {
    width: 90%; /* Make images almost full width on mobile */
    margin: 5% 5%; /* Center images with margin */
  }
}