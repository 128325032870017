.contact-container {
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  padding: 40px;
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
}

.contact-container h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-details p {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.contact-details a {
  color: #0066cc;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}