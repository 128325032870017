.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-logo img {
  height: 50px;
  width: auto;
}

.menu-container {
  position: relative;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-left: 1rem;
}

.navbar-links li a {
  text-decoration: none;
  color: #333;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-links {
    display: none;
    position: fixed;
    top: 80px; /* Adjust this value to match your navbar height */
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    z-index: 999; /* Ensure it's below the navbar but above other content */
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-links li {
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
  }

  .navbar-links li a {
    display: block;
    padding: 0.5rem 0;
  }
}